import React from "react";
import Image from "next/image";
import { configuration } from "../../helpers/config";

const ExperienceIcon = ({ icon, title, href, onClick,Id,alt_text }) => {
  // large icon with link mainly used in brand/ cms pages .
  return (
    <div className="icons-grid text-center" id={Id}>
      <a
        className="text-center my-10 inline-block cursor-pointer"
        id={Id}
        href={href}
        target={""}
        rel="noreferrer"
        onClick={onClick}
      >
        <Image
         id={Id}
          height="40px"
          width="40px"
          className="text-center"
          src={icon}
          alt={alt_text}
        />
        <p className="font-bold mt-3 text-[16px] uppercase">{title}</p>
      </a>
    </div>
  );
};

export default ExperienceIcon;

import React, { useState } from "react";
import Image from "next/image";
import LinkButton from "../components/button";
import ReactPlayer from "react-player";
import { configuration } from "../helpers/config";
import { CarPageAllSections } from "../pages/pages/templates/all_components";
import ContactNavigate from "./contact-us/contact-navigate";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import useWindowSize from "../hooks/useWindowSize";
import { toggleForm } from "../redux/ActionCreator";
import Link from "next/link";

export default function CommonHomeCard({
  videoLink,
  cardClassName,
  contentClassName,
  youtube_url,
  imgSrc,
  alt_text,
  alt_text_mob,
  Id,
  imgHeight,
  imgWidth,
  imgMobileSrc,
  imgMobileWidth,
  imgMobileHeight,
  cardTitle,
  isBannerClickable,
  cardDescription,
  cardBtnText,
  cardBtnText2,
  cardBtnText3,
  cardLink,
  cardLink2,
  cardLink3,
  dial_toggle,
  cta_dial1,
  cta_dial2,
  form,
  form2,
  form3,
  targets,
  formsData,
  contentBoxClass,
  btnType,
  btnType2,
  emailBtn,
  modifyDesc,
  modifyHead,
  bannerTheme,
  hideCta,
  date,
  target,
  date_enabled,
  firstBannerstyle,
  thirdBannerstyle
}) {
  const [hide, setHide] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const windoWidth = useWindowSize();

  //function to handle link in the card .
  const cardLinkFunc = (
    cardBtnText,
    cardLink,
    target,
    formToBeSelected,
    bannerTheme,
  ) => {
    
    const theme = {
      color: bannerTheme?.ctaColor,
      font: bannerTheme?.ctaFontSize,
    };
    
    return (
      <>
        {!form ? (
          <LinkButton
            type={btnType}
            buttontext={cardBtnText}
            Id={Id}
            href={
              youtube_url
                ? youtube_url
                : CarPageAllSections.includes(cardLink.split("#")[1])
                ? `/cars/${cardLink}`
                : cardLink
            }
            classname="button_dark_arrow"
            target={targets}
            // fontManage={`text-[${bannerTheme?.ctaColor}] text-[${bannerTheme?.ctaFontSize}]`}
            fontManage={theme}
          />
        ) : (
          <>
            <div className="compareItem__CTAs !block">
              <LinkButton
              Id={Id}
                type={"button_dark_arrow_button"}
                buttontext={cardBtnText}
                target={target}
                classname="button_dark_arrow fullBorder__btn"
                onClick={() => {
                  dispatch(toggleForm(form));
                }}
              />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div
      className={`${
        cardClassName ? cardClassName + " commonHomeCard" : "commonHomeCard"
      } ${cardLink && isBannerClickable ? "cursor-pointer" : ""} `}
      onClick={() => {
        if (isBannerClickable) {
          router.push(cardLink);
        }
      }}
    >
      <div className={`${hide && "hidden"} commonHomeCard__image`} id={Id}>
        {videoLink ? (
          <ReactPlayer
            url={configuration.IMG_URL + videoLink}
            playing={true}
            loop={true}
            volume={0}
            height={imgHeight}
            width={"100%"}
            playsinline
          />
        ) : (
          <>
          {/* {console.log("cardLink multitab--->",typeof cardLink=="undefined")} */}

            {!hideCta && (cardLink && typeof cardLink!="undefined") ? (
              <div>
                <Link
                  className="cursor-pointer"
                  href={
                    cardLink
                      ? CarPageAllSections.includes(cardLink.split("#")[1])
                        ? `/cars/${cardLink}`
                        : cardLink
                      : ""
                  }
                >

                  <div
                    className={
                      "cursor-pointer hidden md:flex md:justify-center"
                    }
                  >
                    {youtube_url ? (
                                      <ReactPlayer
                                           url={youtube_url}
                                            playing={false}
                                            controls={true}
                                            loop={true}
                                            volume={0}
                                            width={imgWidth}
                                            height={imgHeight}
                                            playsinline
                                        />
                        ):(
                    <Image
                      src={imgSrc}
                      onError={(e) => {
                        setHide(true);
                      }}
                      className=""
                      height={imgHeight}
                      width={imgWidth}
                      alt={alt_text}
                    />
                        )}
                  </div>
                </Link>

                <Link
                  className="cursor-pointer"
                  href={
                    cardLink
                      ? CarPageAllSections.includes(cardLink.split("#")[1])
                        ? `/cars/${cardLink}`
                        : cardLink
                      : ""
                  }
                >
                  <div className="flex md:hidden">
                  {youtube_url ? (
                                      <ReactPlayer
                                           url={youtube_url}
                                           playing={false}
                                           controls={true}
                                            volume={0}
                                            width={imgMobileWidth}
                                            height={imgMobileHeight}
                                            playsinline
                                        />
                        ):(
                    <Image
                      src={imgMobileSrc}
                      className=""
                      height={imgMobileHeight}
                      width={imgMobileWidth}
                      onError={(e) => {
                        setHide(true);
                      }}
                      alt={alt_text_mob ? alt_text_mob : alt_text}
                    />
                        )}
                  </div>
                </Link>
              </div>
            ) : (
              <div>
                <div className={"hidden md:flex md:justify-center"}>
                {youtube_url ? (
                                      <ReactPlayer
                                           url={youtube_url}
                                           playing={false}
                                           controls={true}
                                            loop={true}
                                            volume={0}
                                            width={imgWidth}
                                            height={imgHeight}
                                            playsinline
                                        />
                        ):(
                  <Image
                    src={imgSrc}
                    onError={(e) => {
                      setHide(true);
                    }}
                    className=""
                    height={imgHeight}
                    width={imgWidth}
                    alt={alt_text}
                  />
                        )}
                </div>

                <div className="flex md:hidden">
                {youtube_url ? (
                                      <ReactPlayer
                                           url={youtube_url}
                                           playing={false}
                                           controls={true}
                                            loop={true}
                                            volume={0}
                                            width={imgMobileWidth}
                                            height={imgMobileHeight}
                                            playsinline
                                        />
                        ):(
                  <Image
                    src={imgMobileSrc}
                    className=""
                    height={imgMobileHeight}
                    width={imgMobileWidth}
                    onError={(e) => {
                      setHide(true);
                    }}
                    alt={alt_text_mob ? alt_text_mob : alt_text}
                  />
                        )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={`commonHomeCard__details bottom-[60px] container mx-auto ${contentBoxClass}`}
      >
        <div
          className={`commonHomeCard__details--info flex flex-col justify-between ${contentClassName} ${firstBannerstyle} ${thirdBannerstyle}`}
        >
          {
            <>
              {cardTitle && (
                <h3
                  style={
                    windoWidth.width > 1024
                      ? {
                          fontSize: bannerTheme?.titleFontSize,
                          color: bannerTheme?.titleColor,
                        }
                      : { color: bannerTheme?.titleColor }
                  }
                  className={`text-heading md:text-sub-heading  commonHomeCard__details--title mt-[50px] ${modifyHead}`}
                >
                  {cardTitle}
                </h3>
              )}
              {date_enabled && <span>{date}</span>}
              {cardDescription && dial_toggle ? null : cardDescription ? (
                <p
                  style={
                    windoWidth.width > 1024
                      ? {
                          fontSize: bannerTheme?.descFontSize,
                          color: bannerTheme?.descColor,
                        }
                      : { color: bannerTheme?.descColor }
                  }
                  className={`text-default md:text-small2 commonHomeCard__details--desc mb-8 mt-6 ${modifyDesc} unreset overflow-hidden p-[5px]`}
                  dangerouslySetInnerHTML={{ __html: cardDescription }}
                />
              ) : null}
           
              {
                // cardBtnText && cardLinkFunc(cardBtnText,cardLink,form)
                cardBtnText && dial_toggle
                  ? null
                  : cardBtnText
                    ? !hideCta &&
                      cardLinkFunc(cardBtnText, cardLink, form, bannerTheme)
                    : null
              }
              {cardBtnText2 && dial_toggle
                ? null
                : cardBtnText2
                  ? !hideCta &&
                    cardLinkFunc(cardBtnText2, cardLink2, form2, bannerTheme)
                  : null}
              {cardBtnText3 && dial_toggle
                ? null
                : cardBtnText3
                  ? !hideCta &&
                    cardLinkFunc(cardBtnText3, cardLink3, form3, bannerTheme)
                  : null}
            </>
          }

          {/* Contact Number  */}
          {dial_toggle && (
            <div className="contact-grid-first h-[137px]">
              {cta_dial1 && (
                <ContactNavigate
                Id={Id}
                  textContent={cta_dial1}
                  // imgSrc="/dialerIcon.svg"
                  imgSrc={`${configuration.IMG_STATIC_URL}dialerIcon.svg`}
                  marginClass="mt-0"
                  href={`tel:${cta_dial1}`}
                />
              )}

              {cta_dial2 && (
                <ContactNavigate
                Id={Id}
                  textContent={cta_dial2}
                  // imgSrc="/dialerIcon.svg"
                  imgSrc={`${configuration.IMG_STATIC_URL}dialerIcon.svg`}
                  marginClass={"mt-[16px]"}
                  href={`tel:${cta_dial2}`}
                />
              )}
            </div>
          )}

          {emailBtn && (
            <ContactNavigate
            Id={Id}
              textContent="Küldjön emailt"
              imgSrc={`${configuration.IMG_STATIC_URL}inboxIcon.svg`}
              marginClass={"mt-[16px]"}
              href="mailto:flottainfo@suzuki.hu"
            />
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState,useRef,useEffect } from "react";
import Image from "next/image";
import { configuration } from '../../helpers/config';
import axios from "axios";
import { Constants } from "../../helpers/constants";
import Loader from "../Loader/Loader";
import useIsMobile from "../../hooks/useIsMobile";
// FLEET SLIDER FOR FLEET PAGE
const FleetPageSwipe = ({ head, desc, fleetPricings,item,getmodedesclaimer,setgetmodedesclaimer }) => {
  

  useEffect(() => {
    if (fleetPricings && fleetPricings.length > 0) {
      // Automatically select the first model on the first render
      setSelectedModelCode(fleetPricings[0]?.model_code);
    }
  }, [fleetPricings]);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [fetchedModelsMap, setFetchedModelsMap] = useState(new Map()); // Track selected item
  const [fetcheddesclaimerMap, setFetcheddesclaimerMap] = useState(new Map()); // Trackem selected it
  const [modelDetails, setModelDetails] = useState([]);
  const [fetchedModels, setFetchedModels] = useState(new Set());
  const sliderRef = useRef(null); // Reference to the Slider
  const isMounted = useRef(true); 
  const [selectedModelCode, setSelectedModelCode] = useState(null);
  const [currencycode,setCurrencycode]= useState()
  const disclaimerMap = useRef(new Map()); // Persistent map to store previously selected val objects
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredFleetPricings, setFilteredFleetPricings] = useState([]);
  const [showVariantDropdown, setShowVariantDropdown] = useState(false); // Controls variant dropdown visibility
  const [selectedVariant, setSelectedVariant] = useState(null); // Stores the selected variant
  const [filteredVariants, setFilteredVariants] = useState(modelDetails?.variants || []);
  const modelIconRef = useRef(null);
  const variantIconRef = useRef(null);
  const yearIconRef= useRef(null);
  const modelDropdownRef = useRef(null);
  const variantDropdownRef = useRef(null);
  const yearDropdownRef = useRef(null);
  const [filteredSellingPrices, setFilteredSellingPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [availableYears, setAvailableYears] = useState([]);
  const mobile = useIsMobile();

 // Fetch available years from the API
 useEffect(() => {
  const fetchAvailableYears = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${configuration.APP_URL_PRODUCTS}model/`)
      const data = response.data?.data || [];
      const variants = data.flatMap((item) => item.variants || []);
      const years = [...new Set(variants.map((variant) => variant.year).filter((year) => year !== "NA"))];
      setAvailableYears(years);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching available years:", error);
      setLoading(false);
    }
  };
  fetchAvailableYears();
}, []);

// Handle Year Selection
const handleFilterYear = (year) => {
  setSelectedYear(year);
  const filtered = modelDetails?.variants?.filter(
    (variant) => variant.year === year
  );
  setFilteredVariants(filtered);
  setShowYearDropdown(false); // Close the dropdown
};


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);


  
  const handleModelDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
    setShowVariantDropdown(false); // Ensure the variant dropdown closes when model dropdown toggles
  };
  
  const handleVariantDropdownToggle = () => {
    setShowVariantDropdown((prev) => !prev);
    setShowDropdown(false); // Ensure the model dropdown closes when variant dropdown toggles
  };

  useEffect(() => {
    setFilteredSellingPrices(
      modelDetails?.variants?.flatMap((variant) => variant?.sellingPriceDetails || [])
    );
  }, [modelDetails]);

  // Handle clicks outside both dropdowns
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        modelDropdownRef.current &&
        !modelDropdownRef.current.contains(event.target) && // Not inside the model dropdown
        modelIconRef.current &&
        !modelIconRef.current.contains(event.target) && // Not inside the model icon
        showDropdown // Close only if the model dropdown is open
      ) {
        setShowDropdown(false);
      }
  
      if (
        variantDropdownRef.current &&
        !variantDropdownRef.current.contains(event.target) && // Not inside the variant dropdown
        variantIconRef.current &&
        !variantIconRef.current.contains(event.target) && // Not inside the variant icon
        showVariantDropdown // Close only if the variant dropdown is open
      ) {
        setShowVariantDropdown(false);
      }

      if (
        showYearDropdown && // Only act if the year dropdown is open
        yearDropdownRef.current &&
        !yearDropdownRef.current.contains(event.target) && // Click is outside year dropdown
        yearIconRef.current &&
        !yearIconRef.current.contains(event.target) // Click is outside year icon
      ) {
        setShowYearDropdown(false);
      }
    };
  
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showDropdown, showVariantDropdown,showYearDropdown]);
  


 // Function to handle filtering by model or reset filter
 useEffect(() => {
    setFilteredFleetPricings(fleetPricings);
  }, [fleetPricings]);

  // Function to handle filtering by model or reset filter
  const handleFilterModel = (modelCode) => {
    setShowDropdown(false); // Close the dropdown
    if (modelCode) {
      // Highlight the selected model
      setSelectedModelCode(modelCode);
      setFilteredFleetPricings(
        fleetPricings.filter((item) => item.model_code === modelCode)
      );
    } else {
      // Reset to show all models and highlight the first model
      setFilteredFleetPricings(fleetPricings);
      setSelectedModelCode(fleetPricings?.[0]?.model_code || null); // Highlight the first model
    }
  };
  

 // Reset variants when modelDetails changes
 useEffect(() => {
    setFilteredVariants(modelDetails?.variants || []);
  }, [modelDetails]);

 // Function to handle filtering by model or reset filter
 const handleFiltervariant = (variantName) => {
  // console.log("variant clicked");
  setShowVariantDropdown(false); // Close the dropdown
  setSelectedVariant(variantName);

  if (variantName) {
    // Find the selected variant
    const selectedVariant = modelDetails.variants.find(
      (variant) => variant.variant_name === variantName
    );

       // Reset the year filter when a variant is selected
       setSelectedYear(null); // Reset the year filter
       setShowYearDropdown(false);
    // Update filteredVariants and filteredSellingPrices
    setFilteredVariants([selectedVariant]);
    setFilteredSellingPrices(selectedVariant?.sellingPriceDetails || []);
  } else {
    // Reset to show all variants and prices
    setFilteredVariants(modelDetails.variants);
    setFilteredSellingPrices([]);
  }
};


  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };



  }, []);

 // Map to store fetched model data, including currencycode.

 useEffect(() => {
    // Automatically set the first model as selected on initial render
    if (fleetPricings?.length > 0) {
    const initialModel = fleetPricings[0];
    setModelDetails(initialModel);
    fetchSellingPrices(initialModel);
    // Retrieve or set currencycode for the initial model
    const initialCurrency =
      fetchedModelsMap.get(initialModel?.model_code)?.currencycode || ""; // Fallback to empty string
    setCurrencycode(initialCurrency);
  }
}, [fleetPricings]);

useEffect(() => {
  if (selectedVariant) {
    const matchingVariant = modelDetails?.variants?.find(
      (variant) => variant.variant_name === selectedVariant
    );

    if (matchingVariant) {
      setFilteredSellingPrices(matchingVariant.sellingPriceDetails || []);
    }
  }
}, [selectedVariant, modelDetails]);

useEffect(() => {
  if (modelDetails?.variants?.length > 0) {
    const initialVariant = modelDetails.variants[0];
    setSelectedVariant(initialVariant.variant_name);
    setFilteredSellingPrices(initialVariant.sellingPriceDetails || []);
  }
}, [modelDetails]);

// Clear year filter when modelDetails or selectedVariant changes
useEffect(() => {
  if (selectedVariant || modelDetails) {
    setSelectedYear(null); // Reset the year filter
    setShowYearDropdown(false); // Close the year dropdown if open
  }
}, [modelDetails, selectedVariant]);

  const fetchSellingPrices = async (selectedModel) => {

    item?.fleetmodels?.forEach((val) => {
        if (val?.modelid === selectedModel?._id && val?.modelname === selectedModel?.model_name) {
          // Check if the val is already stored in the map
         
            // If not present, store the current val in the map
            disclaimerMap.current.set(val?.modelcode, val);
      
            // Set the current val directly to the state
            setgetmodedesclaimer(val);
          }
      });
      
    try {
      const currentDate = new Date().toISOString().split("T")[0]; // Format date
  
      const updatedVariants = await Promise.all(
        selectedModel?.variants?.map(async (variant) => {
          try {
            const res = await axios.get(
              `${configuration.COMMON_MASTER_API}/sellingPrices?modelCode=${selectedModel?.model_code}&variantCode=${variant?.variant_code}&startDate=${currentDate}&endDate=${currentDate}&dealerCode=${configuration.DEALER_CODE}&lob=${configuration.COMMON_MASTER_LOB}`,
              configuration.HEADER_DEALER_LOCATOR
            );
  
            const fetchedCurrency = res?.data?.data[0]?.currency?.code || "";
            setCurrencycode(fetchedCurrency);
            if (res?.data?.data) {
              const matchingData = res.data.data.find(
                (e) => e.sellingPriceDetails[0]?.variantCode === variant?.variant_code
              );
  
              // If matchingData exists, add sellingPriceDetails to the variant
              if (matchingData) {
                return {
                  ...variant,
                  sellingPriceDetails: matchingData.sellingPriceDetails || [], // Merge sellingPriceDetails
                };
              }
            }



            // item?.map
            // If no matching data, return the original variant
            return variant;
          } catch (error) {
            console.error(
              `Error fetching selling price for variant: ${variant.variant_code}`,
              error
            );
            return variant; // Return original variant in case of error
          }
        })
      );
  
      // Update the fetched models map
      if (isMounted.current) {
        setFetchedModelsMap((prevMap) => {
          const newMap = new Map(prevMap);
          newMap.set(selectedModel.model_code, { ...selectedModel, variants: updatedVariants });
          return newMap;
        });
  
        // Set the current model details
        setModelDetails({ ...selectedModel, variants: updatedVariants });
      }
    } catch (error) {
      console.error("Error fetching selling prices:", error);
    }
  };
  
  const handleOnClick = (selectedItem) => {
    // console.log("Clicked item:", selectedItem);
    setSelectedModelCode(selectedItem?.model_code); // Set the selected model code
  
    // Check if the model has already been fetched
    if (fetchedModelsMap.has(selectedItem?.model_code)) {
      // Retrieve from map instead of raw selectedItem
      const storedVal = disclaimerMap.current.get(selectedItem?.model_code);
      setModelDetails(fetchedModelsMap.get(selectedItem?.model_code));
      setgetmodedesclaimer(storedVal);
    } else {
      fetchSellingPrices(selectedItem);
    }
  };
  // Settings for the second div (details slider)
  const setting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    focusOnSelect: true,
    centerMode: true, // Ensures only one item is centered
    speed: 500, // Controls the sliding speed
    easing: "ease", // Easing for smooth transition
  };

 
  return (
    <div className="fleetpriceList-wrapper">
      <div className="fleetprice-heads mb-[35px] md:mb-[60px] lg:mb-[60px]">
        <h1
          className={` lg:max-w-[100%] uppercase leading-[30px] text-[#001151] text-[30px] mb-[12px] md:mb-[24px] lg:mb-[24px]`}
        >
          {item?.title}
        </h1>
        <p
          className={` text-[#000000] lg:text-[16px] w-[80%] lg:max-w-[60%] md:max-w-[60%] text-[16px]`}
        >
          {item?.description}
        </p>
      </div>
      {/* <Slider
        {...settings}
        className="tabs-slide mb-[40px] md:border-b-2 md:border-b-gray pl-[5%] md:pl-0 whitespace-nowrap "
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        swipeToSlide={true}
        focusOnSelect={true}
      > */}
       
      {/* </Slider> */}

      {/* <Slider
        {...setting}
        className="content-slide mb-[60px]"
        asNavFor={nav2}
        ref={(slider1) => setNav1(slider1)}
      > */}
        {/* {fleetPricings &&
          fleetPricings.map((data, index) => {
            return ( */}
                <div className="fleetpriceList-wrapper">
                  {loading ?(
                    <div className="flex justify-center items-center">
                      <Loader /> 
                    </div>
              
                  ):(
              <>
              {item?.fleetmodels?.length>0 &&
<div className=" h-[700px] overflow-hidden border-b-[1px] border-[#D0D0D1] hidden md:flex">
<div className="flex-shrink-0 w-[14.28%] overflow-y-auto border-l border-b border-t border-[silver] relative"  style={{ direction: "rtl"}}>
      {/* Title Section with Filter Icon */}
      <div className="sticky top-0 bg-[#001151] z-[2] flex justify-center items-center gap-2 px-4 py-2 pt-[0.55rem]" style={{height:"3.17rem", direction: "ltr"}}>
    <p className="font-SuzukiPROBold text-[16px] capitalize leading-[24px] text-inverted">
      {Constants?.fleet?.MODEL}
    </p>
    {/* Filter Icon */}
    <button
      ref={modelIconRef}
      onClick={handleModelDropdownToggle}
      className="text-inverted focus:outline-none flex justify-center"
      aria-label="Filter"
      disabled={loading}
    >
      <span className="icon-filter"></span> {/* Icon from CSS class */}
    </button>
  </div>

      {/* Dropdown for Filtering */}
      {showDropdown && (
        <div  ref={modelDropdownRef} className="absolute top-[48px] left-0 bg-white shadow-md rounded-md w-full z-[1] overflow-hidden bg-inverted">
            {/* Reset Filter */}
          <div
            className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-left text-red-600 font-bold"
            onClick={() => handleFilterModel(null)}
          >
          {Constants?.fleet?.SHOW_ALL_MODELS}
          </div>
          {fleetPricings?.map((model, i) => (
            <div
              key={i}
              className="px-4 py-2 bg-gray-100 hover:bg-gray-500 cursor-pointer text-left"
              onClick={() => handleFilterModel(model.model_code)}
            >
              {model.model_name}
            </div>
          ))}
          
        </div>
      )}

      {/* Scrollable Content */}
      {filteredFleetPricings &&
        filteredFleetPricings.map((item, i) => (
          <div
            key={i}
            className={`fleet-slide-grid text-center cursor-pointer transition duration-300 ${
              selectedModelCode && selectedModelCode !== item.model_code
                ? "opacity-50 " // Blur and dim non-selected models
                : "opacity-100 " // Clear for the selected model
            }`}
            onClick={() => handleOnClick(item)}
          >
            <Image
              src={`${configuration.IMG_URL}${item.images.desk_small}`}
              width={184}
              height={98}
              objectFit="cover"
            />
            <span className="text-sub-heading text-[#001151] block mt-[6px] font-suzukiBold uppercase text-center md:text-default min-w-[125px] md:text-tab-color px-[20px] py-[10px] relative border-b-2 bottom-[0px] border-transparent focus:outline-none">
              {item.model_name}
            </span>
          </div>
        ))}
    </div>


    <div className="flex overflow-x-auto h-[100%] border border-[silver] w-[100%]">
  {/* Variant Section */}
  <div className="relative w-[40.28%] h-[100%] border-gray-300 shadow-[1px_0_0_0_rgba(222,215,225,1)]">
  <div className="sticky top-0 bg-[#001151] z-[2] flex justify-start gap-2 items-center px-4 pb-[0.8rem] pt-[0.35rem]">
  <p className="font-SuzukiPROBold text-[16px] capitalize leading-[24px] text-inverted">{Constants?.fleet?.VARIANT}</p>
          {/* Filter Icon */}
      <button
        ref={variantIconRef}
    onClick={handleVariantDropdownToggle}
        className="text-inverted focus:outline-none flex justify-center"
        aria-label="Filter"
        disabled={loading}
      >
    <span className="icon-filter"></span> {/* Icon from CSS class */}
      </button>

      {/* <div className="flex items-center ml-auto"> */}
    {/* Year Filter Icon */}
        {/* <button
          ref={yearIconRef}
          onClick={() => setShowYearDropdown(!showYearDropdown)}
          className={`bg-white border border-gray-300 text-black py-2 px-4 rounded-md flex items-center justify-between w-[120px] focus:outline-none ${
            loading ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
          }`}
          aria-label="Filter"
    style={{backgroundColor:"white",paddingLeft:"0.5rem",paddingRight:"0.5rem",paddingTop:"0.3rem",paddingBottom:"0.3rem"}}
          disabled={loading}
        > */}
    {/* Selected Year */}
    {/* <span className="text-base font-medium">{selectedYear || Constants?.fleet?.SELECT_YEAR}</span> */}
    {/* Dropdown Icon */}
          {/* <svg
      className={`h-4 w-4 transition-transform ${showYearDropdown ? "rotate-180" : "rotate-0"}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg> */}
        {/* </button> */}
      {/* </div> */}
  
        {/* Dropdown for Year Filtering */}
  {/* {showYearDropdown && (
    <div ref={yearDropdownRef} className="absolute top-full left-0 bg-white shadow-md rounded-md w-full z-[2] overflow-hidden bg-inverted"> */}
      {/* Year Options */}
        {/* Check if availableYears is empty */}
    {/* {availableYears.length === 0 ? (
          <div className="px-4 py-2 text-left text-gray-500">
            {Constants?.fleet?.NO_YEAR_AVAILABLE}
          </div>
    ) : (
      <>
        <div
          className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-left text-red-600 font-bold"
          onClick={() => handleFilterYear(null)}
        >
          {Constants?.fleet?.SHOW_ALL_YEARS} */}
        </div>
      {/* Year Options */}
        {/* {availableYears.map((year, i) => (
          <div
            key={i}
            className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-left text-red-600 font-bold"
            onClick={() => handleFilterYear(year)}  // handle year filtering
          >
            {year}
          </div>
        ))}
      </>
    )}
    </div>
  )}
    </div> */}



      {/* Dropdown for Filtering */}
      <div className="relative">
  {showVariantDropdown && (
    <div   ref={variantDropdownRef} className="absolute top-full left-0 bg-white shadow-md rounded-md w-full z-[2] overflow-hidden bg-inverted">
      {/* Reset Filter */}
      <div
        className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-left text-red-600 font-bold"
        onClick={() => handleFiltervariant(null)}
      >
       {Constants?.fleet?.SHOW_ALL_VARIANTS}
      </div>
      {modelDetails?.variants?.map((variant, variantIndex) => (
        <div
          key={variantIndex}
          className="px-4 py-2 bg-gray-100 hover:bg-gray-500 cursor-pointer text-left"
          onClick={() => handleFiltervariant(variant?.variant_name)}
        >
          {variant.variant_name}
        </div>
      ))}
    </div>
  )}
</div>

    {filteredVariants?.map((variant, variantIndex) => (
    <div
      key={variantIndex}
      className="md:pr-[50px] w-full lg:pr-[80px] fleetprice-slider-grid flex-col md:flex-row lg:flex-row py-[24px] px-[10px] md:px-[32px] lg:px-[32px] flex justify-between border-b-[1px] border-[#D0D0D1] md:border-[#D0D0D1] bg-inverted"
    >
      <p className=" m-0 font-suzukiBold text-[14px] md:text-[16px] lg:text-[16px] text-[#000000] capitalize leading-[17px] md:leading-[24px] lg:leading-[24px]">
          {variant?.variant_name} 
           {/* {variant?.year && variant.year !== "NA" && ` (${Constants?.fleet?.MODEL_YEAR}: ${variant?.year})`} */}
      </p>
    </div>
    ))}
    </div>


  {/* Second child with scrollbar (12 parts out of 14) */}
 
 
  {(item?.price1displayname || item?.price2displayname || item?.price3displayname || item?.price4displayname) && (
  <div className="flex-grow overflow">
    <table className="w-full table-auto border-collapse border-l border-[silver]">
              {/* Table Header */}
              <thead className="sticky top-0 bg-[#001151] z-3 h-12" style={{height:"3.15rem"}}>
        <tr>
                    {item?.price1displayname &&
            <th className="font-SuzukiPROBold text-[16px] capitalize leading-[24px] text-inverted py-2 px-4 text-center">
              {item?.price1displayname}
            </th>
                      }
                      {item?.price2displayname &&
            <th className="font-SuzukiPROBold text-[16px] leading-[24px] text-inverted py-2 px-4 text-center">
              {item?.price2displayname}
            </th>
                  }
                  {item?.price3displayname &&
            <th className="font-SuzukiPROBold text-[16px] capitalize leading-[24px] text-inverted py-2 px-4 text-center">
              {item?.price3displayname}
            </th>
                  }
                  {item?.price4displayname &&
            <th className="font-SuzukiPROBold text-[16px] capitalize leading-[24px] text-inverted py-2 px-4 text-center">
              {item?.price4displayname}
            </th>
                  }
        </tr>
      </thead>
              {/* Table Body */}
              <tbody>
                {filteredVariants.map((variant, variantIndex) => (
                  <tr
                    key={variantIndex}
                    className="bg-inverted border-b-[1px] border-[#D0D0D1]"
                  >
                    {item?.price1displayname && (
                      <td className="py-[24px] px-[20px] md:px-[32px] font-suzukiBold text-[16px] text-[#00368F] capitalize leading-[24px] text-center">
                        {variant?.sellingPriceDetails?.[0]?.[item?.price1]
                          ? `${new Intl.NumberFormat('hu-HU', { useGrouping: true })
                          .format(variant?.sellingPriceDetails?.[0]?.[item?.price1])
                          .replace(/\s/g, '.')} ${configuration.COUNTRY_CODE =="HUN" ? "ft" : currencycode}`
                          : "-"}
                      </td>
                    )}
                    {item?.price2displayname && (
                      <td className="py-[24px] px-[20px] md:px-[32px] font-suzukiBold text-[16px] text-[#00368F] capitalize leading-[24px] text-center">
                        {variant?.sellingPriceDetails?.[0]?.[item?.price2]
                          ? `${new Intl.NumberFormat('hu-HU', { useGrouping: true })
                          .format(variant?.sellingPriceDetails?.[0]?.[item?.price2])
                          .replace(/\s/g, '.')} ${configuration.COUNTRY_CODE =="HUN" ? "ft" : currencycode}`
                          : "-"}
                      </td>
                    )}
                    {item?.price3displayname && (
                      <td className="py-[24px] px-[20px] md:px-[32px] font-suzukiBold text-[16px] text-[#00368F] capitalize leading-[24px] text-center">
                        {variant?.sellingPriceDetails?.[0]?.[item?.price3]
                          ? ` ${new Intl.NumberFormat('hu-HU', { useGrouping: true })
                          .format(variant?.sellingPriceDetails?.[0]?.[item?.price3])
                          .replace(/\s/g, '.')} ${configuration.COUNTRY_CODE =="HUN" ? "ft" : currencycode}`
                          : "-"}
                      </td>
                    )}
                    {item?.price4displayname && (
                      <td className="py-[24px] px-[20px] md:px-[32px] font-suzukiBold text-[16px] text-[#00368F] capitalize leading-[24px] text-center">
                        {variant?.sellingPriceDetails?.[0]?.[item?.price4]
                          ? ` ${new Intl.NumberFormat('hu-HU', { useGrouping: true })
                          .format(variant?.sellingPriceDetails?.[0]?.[item?.price4])
                          .replace(/\s/g, '.')} ${configuration.COUNTRY_CODE =="HUN" ? "ft" : currencycode}`
                          : "-"}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
    </table>
  </div>
  )}
</div>
</div>
}
      </>
       )}
    </div>


     {/* mobile view */}
     {mobile && filteredFleetPricings && filteredFleetPricings.length > 0 && (<div className=" md:hidden grid justify-items-center">
        {/* Header Models UI */}
        <div className="flex overflow-x-auto scrollbar-hidden w-[80vw] text-center">
          {filteredFleetPricings &&
            filteredFleetPricings.map((item, i) => (
              <div
                key={i}
                className={`fleet-slide-grid text-center cursor-pointer transition duration-300  ${
                  selectedModelCode && selectedModelCode !== item.model_code
                    ? "opacity-50 " // Blur and dim non-selected models
                    : "opacity-100 border-b-[7px] border-[#DF003A]" // Clear for the selected model
                }`}
                onClick={() => handleOnClick(item)}
              >
                <Image
                  src={`${configuration.IMG_URL}${item.images.desk_small}`}
                  width={135}
                  height={70}
                  objectFit="cover"
                />
                <span className="text-default text-[#001151] block mt-[6px] font-suzukiBold uppercase text-center min-w-[135px]  px-[20px] border-transparent focus:outline-none">
                  {item.model_name}
                </span>
              </div>
            ))}
        </div>
        {/* DropDown UI */}
        <div className="flex w-[80vw] overflow-x-auto scrollbar-hidden">
          <div>
            <div className="sticky  bg-[#001151] z-[2] flex justify-left gap-2 items-center px-6 py-4 w-[80vw]">
              <div className="font-SuzukiPROBold text-[16px] capitalize leading-[24px] text-inverted">
                {Constants?.fleet?.VARIANT}
              </div>
              {/* Filter Icon */}
              {modelDetails?.variants && modelDetails?.variants.length > 0 && (
              <button
                ref={variantIconRef}
                onClick={handleVariantDropdownToggle}
                className="text-inverted focus:outline-none flex justify-center"
                aria-label="Filter"
                disabled={loading}
              >
                <span className="icon-filter"></span>
                {""}
                {/* Icon from CSS class */}
              </button>
              )}
              {/* <div className="flex items-center ml-auto"> */}
    {/* Year Filter Icon */}
        {/* <button
          ref={yearIconRef}
          onClick={() => setShowYearDropdown(!showYearDropdown)}
          className={`bg-white border border-gray-300 text-black py-2 px-4 rounded-md flex items-center justify-between w-[120px] focus:outline-none ${
            loading ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
          }`}
          aria-label="Filter"
    style={{backgroundColor:"white",paddingLeft:"0.5rem",paddingRight:"0.5rem",paddingTop:"0.3rem",paddingBottom:"0.3rem"}}
          disabled={loading}
        > */}
    {/* Selected Year */}
    {/* <span className="text-base font-medium">{selectedYear || Constants?.fleet?.SELECT_YEAR}</span> */}
    {/* Dropdown Icon */}
          {/* <svg
      className={`h-4 w-4 transition-transform ${showYearDropdown ? "rotate-180" : "rotate-0"}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      </div> */}
        {/* Dropdown for Year Filtering */}
        {/* {showYearDropdown && (
                <div ref={yearDropdownRef} className="absolute top-full left-0 bg-white shadow-md rounded-md w-full z-[2] overflow-hidden bg-inverted"> */}
                  {/* Year Options */}
                  {/* Check if availableYears is empty */}
                {/* {availableYears.length === 0 ? (
                    <div className="px-4 py-2 text-left text-gray-500">
                      {Constants?.fleet?.NO_YEAR_AVAILABLE}
                    </div>
                ) : (
                  <>
                  <div
                  className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-left text-red-600 font-bold"
                  onClick={() => handleFilterYear(null)}
                >
                {Constants?.fleet?.SHOW_ALL_YEARS}
                </div>
                  {availableYears.map((year, i) => (
                    <div
                      key={i}
                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-left text-red-600 font-bold"
                      onClick={() => handleFilterYear(year)}  // handle year filtering
                    >
                      {year}
                    </div>
                  ))}
                  </>
                )}
                </div>
              )} */}
            </div>
            
               {/* Dropdown for Filtering */}
               <div className="relative">
              {showVariantDropdown && modelDetails?.variants && modelDetails?.variants.length > 0 &&  (
                <div
                  ref={variantDropdownRef}
                  className=" absolute top-0 bg-white shadow-md rounded-md w-full z-50 overflow-hidden bg-inverted"
                >
                  {/* Reset Filter */}
                  <div
                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-left text-red-600 font-bold"
                    onClick={() => handleFiltervariant(null)}
                  >
                    {Constants?.fleet?.SHOW_ALL_VARIANTS}
                  </div>
                  {modelDetails?.variants?.map((variant, variantIndex) => (
                    <div
                      key={variantIndex}
                      className="px-4 py-2 bg-gray-100 hover:bg-gray-500 cursor-pointer text-left"
                      onClick={() => handleFiltervariant(variant?.variant_name)}
                    >
                      {variant.variant_name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* Variant Info Card */}
            {filteredVariants?.map((variant) => {
              return (
                <>
                  <div className="h-4 shadow-[1px_0_0_0_rgba(217,217,217,1)]"/>
                  <div className="w-full border-b border-[#D9D9D9] shadow-[1px_0_0_0_rgba(217,217,217,1)]">
                    <div className="w-full" style={{paddingTop:"0.45rem", paddingBottom:"1rem",paddingLeft:"1rem"}}>
                    <p className="m-0 font-suzukiBold text-[14px] md:text-[16px] lg:text-[16px] text-[#000000] capitalize leading-[17px] md:leading-[24px] lg:leading-[24px]">
          {variant?.variant_name} 
           {/* {variant?.year && variant.year !== "NA" && ` (${Constants?.fleet?.MODEL_YEAR}: ${variant?.year})`} */}
        </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="">
            <table className="min-w-full table-auto border-collapse border-l border-[#D9D9D9] w-max">
              {/* <thead>
                  <tr className="bg-primary-blue">
                    <th className="px-6 py-4 text-inverted text-left w-[60vw]">
                      List Price
                    </th>
                    <th className=" px-6 py-4 text-inverted text-left w-[60vw]">
                      Discounted Price*
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => (
                    <tr
                      key={row.id}
                      className="border-b border-[#D9D9D9] font-suzukiBold text-[16px]"
                    >
                      <td className=" px-6 py-4">{row.listPrice}</td>
                      <td className=" px-6 py-4 text-[#DF003A]">
                        {row.discountedPrice}
                      </td>
                    </tr>
                  ))}
                </tbody> */}
              <thead style={{height:"3.5rem"}}> 
                <tr className="bg-primary-blue">
                  {item?.price1displayname && (
                    <th className="px-6 py-4 text-inverted text-left w-max">
                      {item?.price1displayname}
                    </th>
                  )}
                  {item?.price2displayname && (
                    <th className="px-6 py-4 text-inverted text-left w-max">
                      {item?.price2displayname}
                    </th>
                  )}
                  {item?.price3displayname && (
                    <th className="px-6 py-4 text-inverted text-left w-max">
                      {item?.price3displayname}
                    </th>
                  )}
                  {item?.price4displayname && (
                    <th className="px-6 py-4 text-inverted text-left w-max">
                      {item?.price4displayname}
                    </th>
                  )}
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {filteredVariants.map((variant, variantIndex) => {
                  return (
                    <tr 
                  key={variantIndex}
                  className="bg-inverted border-b-[1px] border-[#D0D0D1]"
                >
                  {item?.price1displayname && (
                    <td className="px-6 py-4 capitalize">
                      {variant?.sellingPriceDetails?.[0]?.[item?.price1]
                        ? `${new Intl.NumberFormat('hu-HU', { useGrouping: true })
                            .format(variant?.sellingPriceDetails?.[0]?.[item?.price1])
                            .replace(/\s/g, '.')} ${configuration.COUNTRY_CODE =="HUN" ? "ft" : currencycode}`
                        : "-"}
                    </td>
                  )}
                  {item?.price2displayname && (
                    <td className="px-6 py-4 capitalize">
                      {variant?.sellingPriceDetails?.[0]?.[item?.price2]
                        ? `${new Intl.NumberFormat('hu-HU', { useGrouping: true })
                            .format(variant?.sellingPriceDetails?.[0]?.[item?.price2])
                            .replace(/\s/g, '.')} ${configuration.COUNTRY_CODE =="HUN" ? "ft" : currencycode}`
                        : "-"}
                    </td>
                  )}
                  {item?.price3displayname && (
                    <td className="px-6 py-4 capitalize">
                      {variant?.sellingPriceDetails?.[0]?.[item?.price3]
                        ? `${new Intl.NumberFormat('hu-HU', { useGrouping: true })
                            .format(variant?.sellingPriceDetails?.[0]?.[item?.price3])
                            .replace(/\s/g, '.')} ${configuration.COUNTRY_CODE =="HUN" ? "ft" : currencycode}`
                        : "-"}
                    </td>
                  )}
                  {item?.price4displayname && (
                    <td className="px-6 py-4 capitalize">
                      {variant?.sellingPriceDetails?.[0]?.[item?.price4]
                        ? `${new Intl.NumberFormat('hu-HU', { useGrouping: true })
                            .format(variant?.sellingPriceDetails?.[0]?.[item?.price4])
                            .replace(/\s/g, '.')} ${configuration.COUNTRY_CODE =="HUN" ? "ft" : currencycode}`
                        : "-"}
                    </td>
                  )}
                </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>)}
      {/*mobile ui ends */}
    </div>
  );
};

export default FleetPageSwipe;

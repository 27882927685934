import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import LinkButton from "../button";
import Image from "next/image";
import axios from "axios";
import { configuration } from "../../helpers/config";
import { Constants } from "../../helpers/constants";
import { formatCurrency, IsValidPrice } from "../../utils/GenUtils";
import Link from "next/link";
import { Tooltip } from "react-tooltip";
import { Modal } from "react-responsive-modal";


const HOME_SUZUKI_CARD = Constants.homeSuzukiCard;
export default function HomeSuzukiCard({
  key,
  cardClassName,
  imgSrc,
  alt_text,
  Id,
  cardTitle,
  cardItemPrice,
  itemFuelType,
  itemSeater,
  cardLink,
  modelCode,
  borderProp,
  offer_price,
  rable_price,
  price,
  local,
  car_page_price_one,
  car_page_price_two,
  car_page_price_three,
  car_page_pricetext_two,
  car_page_pricetext_three,
  additionalInformation
}) {
  // const [bannerPrice, setBannerPrice] = useState(0);
  // const [bannerPrice2, setBannerPrice2] = useState(0);
  // const [bannerPrice3, setBannerPrice3] = useState(0);
  const currentDate = new Date().toISOString();

  const [homepagefirstPrice, sethomepagefirstPrice]=useState(0)
  const [homepagesecondPrice, sethomepagesecondPrice]=useState(0)
  const [homepagethirdPrice, sethomepagethirdPrice]=useState(0)
  const [showPop, setShowPop] = useState(false);
  const [isPriceLoaded,setIsPriceLoaded]=useState(false);

  const LOB_VALUE=useSelector(state=>state?.app?.LobValue);

  const closeIcon = (
    <svg fill="currentColor" viewBox="0 0 20 20" width={14} height={14}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M10 8.586L1.293 0 0 1.293 8.586 10 0 18.707 1.293 20 10 11.414 18.707 20 20 18.707 11.414 10 20 1.293 18.707 0 10 8.586z"
      />
    </svg>
  );
  

  useEffect(() => {
    if (!LOB_VALUE) return;
    //fetching model price from selling price API.
    (async () => {
      if (!LOB_VALUE) return;
      try{
      const res = await axios.get(
        // `${configuration.COMMON_MASTER_API}/sellingPrices?modelCode=${modelCode}&startDate=${currentDate}&endDate=${currentDate}&dealerCode=${configuration.DEALER_CODE}&lob=${configuration.COMMON_MASTER_LOB}`,
        `${configuration.COMMON_MASTER_API}/sellingPrices?modelCode=${modelCode}&startDate=${currentDate}&endDate=${currentDate}&dealerCode=${configuration.DEALER_CODE}&lob=${LOB_VALUE}&status=true`,

        configuration.HEADER_DEALER_LOCATOR,
      );

    
        //new code starts
        let firstPrice=Number(res?.data?.data[0]?.sellingPriceDetails[0]?.[car_page_price_one]);
        let secondPrice=Number(res?.data?.data[0]?.sellingPriceDetails[0]?.[car_page_price_two]);
        let thirdPrice=Number(res?.data?.data[0]?.sellingPriceDetails[0]?.[car_page_price_three]);
          for(let [idx,data] of res.data?.data.entries()){
            if(res?.data?.data[idx]?.sellingPriceDetails.length){
              for(let [index,sellingPrice] of res?.data?.data[idx]?.sellingPriceDetails.entries() ){
               firstPrice=Math.min(firstPrice,Number(sellingPrice[car_page_price_one]));
               secondPrice=Math.min(secondPrice,Number(sellingPrice[car_page_price_two]));
               thirdPrice=Math.min(thirdPrice,Number(sellingPrice[car_page_price_three]));
      
              }
              
              sethomepagefirstPrice(firstPrice)
              sethomepagesecondPrice(secondPrice)
              sethomepagethirdPrice(thirdPrice)
              setIsPriceLoaded(true);
            
            }
          }
        }catch(error) {
          // console.error("Final network failure:", error);
            setIsPriceLoaded(false);
          }
    })();
  }, [modelCode,LOB_VALUE]);

  return (
    <div
      className={
        cardClassName ? cardClassName + " homeSuzukiCard" : "homeSuzukiCard"
      }
    >
      <Link
        className="cursor-pointer"
       
        // href={!local ? `/cars/${cardLink}` : cardLink}
        href={
          !local
            ? cardLink?.includes("http")
              ? cardLink
              // : `/car/${cardLink}`
              :`/${LOB_VALUE==3?'marine':LOB_VALUE==2?'bike':'cars'}/${cardLink}`
            : cardLink
        }
      >
        <div className="cursor-pointer homeSuzukiCard__image"  id={Id}>
          <Image
            src={imgSrc}
            objectFit="contain"
            className={borderProp}
            width={310}
            height={200}
            alt={alt_text}
            style={{zIndex:"-1"}}
          />
        </div>
      </Link>
      <div className="homeSuzukiCard__details">
        <div className="homeSuzukiCard__details--info">
            {/*Additional Information section starts */}
<h3 className="homeSuzukiCard__details--title">{cardTitle}</h3>
          {additionalInformation && additionalInformation?.enable &&
            
            (<div className="homeSuzukiCard__priceInfo">
              <div
                  className="tool-tip-img inline-flex items-center align-middle"
                  onMouseEnter={() => setShowPop(true)}
              >
                  <Image
                      src={`${configuration.IMG_STATIC_URL}assets/images/infoIcon.svg`}
                      height="15"
                      width="15"
                      alt="Info Icon"
                  />
                  <p className="homeSuzukiCard__priceInfo_title">{additionalInformation?.title}</p>
              </div>
              <Modal
                  open={showPop}
                  closeIcon={closeIcon}
                  onClose={() => setShowPop(false)}
                  center
                  classNames={{
                      // overlay: 'customOverlay',
                      modal: 'customModal'
                  }}
              >
                  <div className="tool-tip-wrap md:max-w-[925px] max-w-[350px] md:max-h-[300px] max-h-[500px]">
                      <p className="mb-0 text-[12px] text-inverted font-suzukiRegular">
                          {additionalInformation?.description}
                      </p>
                  </div>
              </Modal>
          </div>
  )
          }

         
            {/*Additional Information section ends */}

          
          {isPriceLoaded &&  IsValidPrice(homepagefirstPrice)  && homepagefirstPrice>0 ? (
            <h5 className="homeSuzukiCard__details--price">
              {configuration?.COUNTRY_CODE!="HUN" && HOME_SUZUKI_CARD.FROM} {formatCurrency(homepagefirstPrice ? homepagefirstPrice : cardItemPrice)}{configuration?.COUNTRY_CODE=="HUN" && "-"+HOME_SUZUKI_CARD.FROM} 
            </h5>
          ):""}
          {(isPriceLoaded && IsValidPrice(homepagesecondPrice) && homepagesecondPrice > 0 && car_page_pricetext_two ) ? (
            <div className="mb-[5px] font-suzukiRegular text-small2 md:text-default">
              {car_page_pricetext_two}  {configuration?.COUNTRY_CODE!="HUN" && HOME_SUZUKI_CARD.FROM}:{" "}
              <span className="mb-[5px] font-suzukiRegular text-small2 md:text-default">
                {formatCurrency(homepagesecondPrice)}{configuration?.COUNTRY_CODE=="HUN" && "-"+HOME_SUZUKI_CARD.FROM}{"*"}
              </span>
            </div>
          ):""}
     
          {(isPriceLoaded && IsValidPrice(homepagethirdPrice) && homepagethirdPrice > 0 && car_page_pricetext_three  ) ? (
            <div className="mb-[5px] font-suzukiRegular text-small2 md:text-default">
              {car_page_pricetext_three}{": "}
              {formatCurrency(homepagethirdPrice) + "*"} 
            </div>
          ):""}
        </div>
        <LinkButton
          type={"button_dark_arrow"}
          buttontext={""}
          Id={Id}
          // href={`/cars/${cardLink}`}
          href={
            !local
              ? cardLink?.includes("http")
                ? cardLink
                // : `/car/${cardLink}`
              :`/${LOB_VALUE==3?'marine':LOB_VALUE==2?'bike':'cars'}/${cardLink}`
              : cardLink
          }
          classname="button_dark_arrow"
          target={cardLink?.includes("http") ? "_blank" : "_self"}
        />
      </div>
    </div>
  );
}

import { Modal } from "react-responsive-modal";
import { Constants } from "../../helpers/constants";

const TERMS_AND_CONDTIONS = Constants.terms_and_conditions;

// component to render terms and condition modal in find perfect suzuki section
const TermsAndConditionsModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} center role="dialog">
      <div className="mx-5 my-6">
        <div className="uppercase font-suzukiBold mb-6 text-center text-sub-heading text-primary-blue">
          Általános Szerződési Feltételek
        </div>
        <div className="text-center text-default">
          {TERMS_AND_CONDTIONS.LINE1}
        </div>
        <div className="text-center text-default mt-4">
          {TERMS_AND_CONDTIONS.LINE2}
        </div>
        <div className="text-center text-default mt-4">
          {TERMS_AND_CONDTIONS.LINE3}
        </div>
         <div className="text-center text-default mt-4">
          {TERMS_AND_CONDTIONS.LINE4}
        </div>
      </div>
    </Modal>
  );
};

export default TermsAndConditionsModal;

import Image from "next/image";
import styles from "../../styles/finance.module.css";
import useIsMobile from "../../hooks/useIsMobile";
import useWindowSize from "../../hooks/useWindowSize";
import { configuration } from "../../helpers/config";
import ReactPlayer from "react-player";
import React from "react";

// Banner component for static pages.
const FinanceBanner = (props) => {
  const mobile = useIsMobile();
  const windoWidth = useWindowSize();
  return (
    <>
      <div
        className={` ${styles.banner_image}`}
        style={{ marginBottom: `${props.bannerClass}`}}
      >
        {/* {props.overlay && (
          <div className={styles.banner_overlay}>{props.overlayClass}</div>
        )} */}
        <div className={styles.banner}>
          {mobile ? (
            props.videoMob ? (
              <ReactPlayer
                url={props.videoMob}
                playing={true}
                volume={0}
                height="100%"
                width="100%"
                loop={true}
                playsinline
              />
            ) : (
              <Image
                className={styles.bannerImage}
                src={props.imageMob}
                height={1080}
                width={768}
                alt={props.alt_image}
              />
            )
          ) : props.video ? (
            <ReactPlayer
              url={props.video}
              playing={true}
              volume={0}
              height="100%"
              width="100%"
              loop={true}
              playsinline
            />
          ) : (
            <Image
              className={styles.bannerImage}
              src={props.image}
              height={1004}
              width={1928}
              alt={props.alt_image}
            />
          )}
        </div>

        <div className={styles.bannerConts}>
          <div className="banner-content container">
            
            <h3
            className={`${styles.banner_content_head} ${props.head == "e VITARA" ? styles.no_uppercase : ""}`}

              // className={`${styles.banner_content_head}`  `${props.head=="e VITARA"?"no-uppercase":""}`}
              style={{
                color: props.titleThemeData?.titleColor,
                fontSize:
                  windoWidth.width > 1024
                    ? props.titleThemeData?.titleFontSize
                    : "",
              }}
            >
              {props.head} 
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceBanner;

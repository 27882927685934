import React,{useState,useEffect} from 'react'
import GetQuoteStyle from "../../components/get-quote/GetQuote.module.css";
import DynamicFormComponent from "../forms/dynamic-form/DynamicFormComponent";
import { useSelector } from "react-redux";
import { configuration } from "../../helpers/config";
import axios from "axios";
import styless from "../../styles/preference.module.css";

const index = (data) => {
const [formsData,setFormsData]=useState()
const LobValue = useSelector(({ app }) => app.LobValue);
const reduxData = useSelector(({ app }) => app);

useEffect(() => {
    if (reduxData.dealerCode != "") {
        (async () => {
          try {
            const res = await axios.get(
              `${configuration.APP_URL_CONTENTS}/forms?lob=${configuration.API_LOB}&dealer_code=${reduxData.dealerCode}`,
              configuration.HEADER_DEALER_LOCATOR_VAPT,
            );
            let requiredForm = res.data[0]?.forms.filter(
              (form) => form.form_name === data?.data?.selectedForm,
            )[0];
            setFormsData(requiredForm);
          } catch (e) {
            console.log(e.message);
          }
        })();
      }
  }, [reduxData.dealerCode,LobValue]);

  return (
    <div>
         <div className="container mb-[100px]">
      {/* {data && data?.data?.sectionTitle && (
        <h1
        className={styless.preference_request_head}
        style={{ marginBottom: "30px" }}
    >
        {data?.data?.sectionTitle}
    </h1>
     )} */}
      <p className={`text-default md:text-small2 commonHomeCard__details--desc  mb-8  unreset overflow-hidden line-clamp-2 pt-3 `}>
        {data?.data?.desc}</p>
     <div className={GetQuoteStyle.get__quote__container}>
    
       <DynamicFormComponent
         hidetitle={true}
         data={formsData}
         onClose={() => {
           // console.log("finished")
         }}
       />
     </div>
   </div>
     
    </div>
  )
}

        export default index
import React, { useRef } from "react";
import LinkButton from "../button";
import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { configuration } from "../../helpers/config";
import { Constants} from "../../helpers/constants";
import {CarPageAllSections} from "../../pages/pages/templates/all_components"
import { toggleForm } from "../../redux/ActionCreator";
import { useDispatch } from "react-redux";
import Link from "next/link";
import ReactPlayer from "react-player";
 
// cards used to highlight a specific feature of a car eg. performance, comfort etc
export default function BrandCard({
  bookMarkId,
  classname,
  imageHeight,
  Id,
  imageWidth,
  imageAlt,
  imageHeightMobile,
  imageWidthMobile,
  cardRightPadding,
  cardContentPadding,
  manageMargin,
  swipeDisable,
  showPercent,
  percentText,
  data,
  bullets,
  bulletText,
  target,
  descBottom,
  local,
}) {
  // settings for slider component
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slider = useRef(null);
  const dispatch = useDispatch();
  return (
    <div
      id={bookMarkId}
      className={`container-fluid ${cardRightPadding} brandPage-cards`}
    >
      <Slider className="news" swipe={!swipeDisable} ref={slider} {...settings}>
        {data?.length > 0 &&
          data?.map((item) => (
            <div
              key={item?.id}
              className={
                classname ? "brandFullCard" + " " + classname : "brandFullCard"
              }
            >
              <div className="brandFullCard__image">
              {
                item.cta_link?
                <div>
                <Link  
                  className="cursor-pointer"
                  href={CarPageAllSections.includes(item.cta_link.split("#")[1])?`/cars/${item.cta_link}`:item?.cta_link}
               >

                <div className="cursor-pointer hidden md:block">
                {item?.youtubeurl ? (
                                      <ReactPlayer
                                           url={item.youtube_url}
                                           playing={false}
                                           controls={true}
                                            loop={true}
                                            volume={0}
                                            width={imageWidth}
                                            height={imageHeight}
                                            playsinline
                                        />
                        ):(
                  <img
                  Id={Id}
                    src={
                      !local
                        ? configuration.IMG_URL + item?.image_desktop
                        : item?.image_desktop
                    }
                    width={imageWidth}
                    height={imageHeight}
                    alt={item.alt_text || item.img_alt_text ||  ""}
                    layout=""
                  />
                )}
                </div>
                </Link>
                <Link  
                   className="cursor-pointer"
                   href={CarPageAllSections.includes(item.cta_link.split("#")[1])?`/cars/${item.cta_link}`:item?.cta_link}
                              >
                <div className="flex md:hidden">
                {item?.youtubeurl ? (
                                      <ReactPlayer
                                           url={item.youtube_url}
                                           playing={false}
                                           controls={true}
                                            loop={true}
                                            width={imageWidthMobile}
                                            height={imageHeightMobile}
                                            playsinline
                                        />
                        ):(
                  <img
                    src={
                      !local
                        ? configuration.IMG_URL + item?.image_mobile
                        : item?.image_mobile
                    }
                    width={imageWidthMobile}
                    height={imageHeightMobile}
                    alt={item.alt_text || item?.img_alt_text}
                  />
                        )}
                </div>
                </Link>
                </div>
                :
              <div>
              <div className="hidden md:block">
              {item?.youtubeurl ? (
                                      <ReactPlayer
                                           url={item.youtube_url}
                                           playing={false}
                                           controls={true}
                                            loop={true}
                                            volume={0}
                                            width={imageWidth}
                                            height={imageHeight}
                                            playsinline
                                        />
                        ):(
                  <img
                    src={
                      !local
                        ? configuration.IMG_URL + item?.image_desktop
                        : item?.image_desktop
                    }
                    width={imageWidth}
                    height={imageHeight}
                    alt={item.alt_text || item?.img_alt_text || ""}
                    layout=""
                  />
                        )}
                </div>
                <div className="flex md:hidden">
                {item?.youtubeurl ? (
                                      <ReactPlayer
                                           url={item.youtube_url}
                                           playing={false}
                                           controls={true}
                                            loop={true}
                                            volume={0}
                                            width={imageWidthMobile}
                                            height={imageHeightMobile}
                                            playsinline
                                        />
                        ):(
                  <img
                    src={
                      !local
                        ? configuration.IMG_URL + item?.image_mobile
                        : item?.image_mobile
                    }
                    width={imageWidthMobile}
                    height={imageHeightMobile}
                    alt={item.alt_text || item?.img_alt_text}
                  />
                        )}
                </div>
              </div>
              }
              </div>
              

              <div
                className={
                  data.length > 1
                    ? `mb-[100px] container mx-auto brandFullCard__details ${cardContentPadding}`
                    : `mb-0 container mx-auto brandFullCard__details ${cardContentPadding}`
                }
                style={{
                  marginLeft: "10px", 
                  marginRight:"10px"
                }}
              >
                <h5 className="brandFullCard__title">{item?.title}</h5>
                <div className="brandFullCard__desc_cta">
                  <div
                    className={`brandFullCard__desc ${manageMargin} unreset`}
                    dangerouslySetInnerHTML={{ __html: item?.desc }}
                  >
                    </div>

                  {bullets && (
                    <div className="mt-[20px]">
                      <ul
                        className="list-disc pl-[20px]"
                        style={{ listStyle: "initial" }}
                      >
                        {item?.bulletText &&
                          item?.bulletText?.map((item, indx) => {
                            return (
                              <li
                                className="text-default mb-[5px] pl-[12px]"
                                key={indx}
                              >
                                {item.descText}
                              </li>
                            );
                          })}
                      </ul>

                      <p className="font-suzukiRegular mt-[20px]">
                        {descBottom}
                      </p>
                    </div>
                  )}

                  {showPercent && (
                    <h2 className=" font-suzukiBold text-primary-blue uppercase ">
                      {percentText}
                    </h2>
                  )}

                  {item?.cta_text && item?.cta_link && (
                    <LinkButton
                    Id={Id}
                      type={"button_dark_arrow"}
                      buttontext={item?.cta_text}
                      target={target}
                      href={
                        item?.youtube_url
                          ? item?.youtube_url // If youtube_url exists, redirect to YouTube
                          : CarPageAllSections.includes(item.cta_link.split("#")[1])
                          ? `/cars/${item.cta_link}`
                          : item?.cta_link
                      }
                      classname="button_dark_arrow"
                    />
                  )}
                  {item?.cta_text && !item?.cta_link && item?.form_id && (
                    <div className="compareItem__CTAs !block">
                    <LinkButton
                    Id={Id}
                      type={"button_dark_arrow_button"}
                      buttontext={item?.cta_text}
                      onClick={() => {
                        dispatch(toggleForm(item?.form_id));
                      }}
                      classname="button_dark_arrow fullBorder__btn"
                    />
                    </div>
                  )}
                </div>

                {data.length > 1 && (
                  <div className="relative slick-arrow-container brandsPage">
                    <button
                      className="slick-arrow brand-slicks-prev"
                      onClick={() => slider?.current?.slickPrev()}
                    >
                      {Constants.brandCard.PREV}
                    </button>
                    <button
                      className="slick-arrow brand-slicks-next"
                      onClick={() => slider?.current?.slickNext()}
                    >
                      {Constants.brandCard.NEXT}
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}

import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import CommonHomeCard from "../commonHomeCard";
import { configuration } from "../../helpers/config";
import LinkButton from "../button";
import { useDispatch } from "react-redux";
import { toggleForm } from "../../redux/ActionCreator";
import { Constants } from "../../helpers/constants";
import { CarPageAllSections } from "../../pages/pages/templates/all_components";
import HomeSuzukiCard from "../find-suzuki/homeSuzukiCard";
import Image from "next/image";
// import { iamegs } from "../../public/Proving-desk.png";
import TermsAndConditionsModal from "../find-suzuki/terms&ConditionsModal";

//Common multi-tab section for all components-> Driving support, Suzuki-hybrid, multitabthree, multitabfour
const MultitabCommmon = ({ data, tempid }) => {
  const dispatch = useDispatch();
  const [isTermsModalVisible, setTermsModalVisibility] = useState(false);
  // const tempid = data?.sectionTitle.replace(/\s+/g, "");
  return (
    <div className="container-fluid">
      <div id={tempid} className="md:mb-[150px] mb-[100px]">
        <div className="container">
          <h3 className="perfectSuzuki__container--sectionHeading mb-[48px]">
            {data?.sectionTitle}
          </h3>
        </div>
        <div className="lg:mb-[100px]">
          <Tabs>
            {/* Tab list for parent Tabs in a section */}
            <div className="container tab-ul">
              <TabList className="react-tabs__tab-list">
                {data?.parents
                  ?.sort((a, b) => a.order_index - b.order_index)
                  ?.map((item, i) => {
                    return <Tab key={i}>{item?.title}</Tab>;
                  })}
              </TabList>
            </div>
            {data?.parents?.map((item, i) => {
              return (
                <>
                  {/* Tab panel for parent tabs */}
                  <TabPanel key={i}>
                    {item.children && item?.children?.length > 0 ? (
                      <Tabs className="react-tabs-children ">
                        {/* Tabs for nested tabs under parent tabs */}
                        <div className="lg:mb-[100px] sm : mb-[50px]">
                          <TabList className="container  react-tabs__tab-list  ">
                            {item?.children?.map((childtab, index) => {
                              return (
                                <>
                                  {childtab != "main" && (
                                    <Tab key={index}>{childtab.title}</Tab>
                                  )}
                                </>
                              );
                            })}
                          </TabList>
                        </div>
                        {/* child tab data */}
                        {item?.children
                          ?.sort((a, b) => a.order_index - b.order_index)
                          ?.map((childtab, index) => {
                            return (
                              <>
                                <TabPanel key={index}>
                                  {/* <div className="w-[100vw] ml-[-150px] pt-[100px]"> */}
                                  {childtab?.desk_image && (
                                    <CommonHomeCard
                                      Id={
                                        "MultiTabChild" +
                                        childtab?.title?.replace(/\s+/g, "")
                                      }
                                      key={i}
                                      imgSrc={`${configuration.IMG_URL}${childtab?.desk_image}`}
                                      imgMobileSrc={`${configuration.IMG_URL}${childtab?.mob_image}`}
                                      alt_text={childtab?.alt_text}
                                      // formsData={formsData}
                                      // form={item?.form_id}
                                      imgHeight={738}
                                      imgWidth={1920}
                                      // contentClassName={` md:max-w-[80%] `}
                                      // headClassName={`min-h-[75px] lg:min-h-[75px] md:min-h-[75px]`}
                                      // descriptClass={`h-[64px] lg:h-[64px] md:h-[64px]`}
                                      imgMobileHeight={564}
                                      imgMobileWidth={768}
                                      // cardTitle={item?.title}
                                      // cardDescription={item?.desc}
                                      // cardBtnText={item?.cta_text}
                                      cardLink={childtab?.ctaLink}
                                      // btnType={"button_dark_arrow"}
                                    />
                                  )}
                                  {/* </div> */}

                                  <div className="flex mt-20 mb-20 sm : flex-col lg:flex-row sm : m-8 container ml-auto mr-auto">
                                    <div className=" sm : w-full">
                                      <div
                                        className="text-[24px]  sm : w-full font-normal font-suzukiBold sm : pb-5 unreset"
                                        dangerouslySetInnerHTML={{
                                          __html: childtab?.short_desc,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="sm : w-full flex items-center justify-center">
                                      <div className="sm : w-full text-[14px] lg:w-[535px] font-suzukiRegular font-[400] unreset">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: childtab?.desc,
                                          }}
                                        ></div>

                                        {childtab?.form_id &&
                                        childtab?.form_id.length > 0 ? (
                                          <>
                                            <div className="compareItem__CTAs !block">
                                              <LinkButton
                                                Id={
                                                  "MultiTabChild" +
                                                  childtab?.title?.replace(
                                                    /\s+/g,
                                                    ""
                                                  )
                                                }
                                                type={
                                                  "button_dark_arrow_button"
                                                }
                                                buttontext={childtab?.ctaText}
                                                classname="button_dark_arrow fullBorder__btn"
                                                onClick={() => {
                                                  dispatch(
                                                    toggleForm(
                                                      childtab?.form_id
                                                    )
                                                  );
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          childtab?.ctaLink && (
                                            <LinkButton
                                              Id={
                                                "MultiTabChild" +
                                                childtab?.title?.replace(
                                                  /\s+/g,
                                                  ""
                                                )
                                              }
                                              type={"button_dark_arrow"}
                                              buttontext={childtab?.ctaText}
                                              href={
                                                childtab?.ctaLink
                                                  ? CarPageAllSections.includes(
                                                      childtab?.ctaLink?.split(
                                                        "#"
                                                      )[1]
                                                    )
                                                    ? `/cars/${childtab?.ctaLink}`
                                                    : childtab?.ctaLink
                                                  : childtab?.ctaLink
                                              }
                                              classname="button_dark_arrow"
                                              // target="_blank"
                                              target={
                                                childtab.target === "self"
                                                  ? ""
                                                  : "_blank"
                                              }
                                            />
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {/* Section for showing available models under child tab */}
                                  {childtab?.models &&
                                  childtab?.models?.length > 0 ? (
                                    <div>
                                      <div className="mb-[65px] text-[30px] font-suzukiBold sm : ml-[10px] container ml-auto">
                                        <h1>
                                          {childtab?.modeltext?.toUpperCase()}
                                        </h1>
                                      </div>
                                      <div className="container mx-auto flex flex-wrap gap-[27px]">
                                        {childtab?.models?.map((carData, i) => {
                                          return (
                                            <>
                                              <div className="2xl:w-[23%] lg:w-[21%] md:w-[22%] w-[46%] flex items-center justify-center mb-[20px] ">
                                                <div className="w-[100%]">
                                                  <HomeSuzukiCard
                                                    Id={
                                                      "MultiTabChild" +
                                                      carData?.model_code?.replace(
                                                        /\s+/g,
                                                        ""
                                                      )
                                                    }
                                                    key={i}
                                                    modelCode={
                                                      carData?.model_code
                                                    }
                                                    cardClassName={""}
                                                    imgSrc={`${configuration.IMG_URL}${carData?.image}`}
                                                    cardTitle={
                                                      carData?.model_name
                                                    }
                                                    itemFuelType={carData?.fuel_type?.toUpperCase()}
                                                    itemSeater={
                                                      carData?.vehicle_type
                                                    }
                                                    cardLink={carData?.page_url}
                                                    car_page_price_one={
                                                      carData.car_page_price_one
                                                    }
                                                    car_page_pricetext_two={
                                                      carData.car_page_pricetext_two
                                                    }
                                                    car_page_pricetext_three={
                                                      carData.car_page_pricetext_three
                                                    }
                                                    car_page_price_two={
                                                      carData.car_page_price_two
                                                    }
                                                    car_page_price_three={
                                                      carData.car_page_price_three
                                                    }
                                                    additionalInformation={
                                                      carData?.additionalInformation
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                      {/*{Constants.allcomponents*/}
                                      {/*  .SHOW_TERMS_AND_CONDITIONS_MULTITAB ==*/}
                                      {/*"true" ? (*/}
                                      {/*  <div className="container">*/}
                                      {/*    <div*/}
                                      {/*      className=" mt-10 underline text-[#3366CC] cursor-pointer w-fit"*/}
                                      {/*      onClick={() =>*/}
                                      {/*        setTermsModalVisibility(true)*/}
                                      {/*      }*/}
                                      {/*    >*/}
                                      {/*      {*/}
                                      {/*        Constants.allcomponents*/}
                                      {/*          .TERMS_AND_CONDITIONS*/}
                                      {/*      }*/}
                                      {/*      **/}
                                      {/*    </div>*/}
                                      {/*    {isTermsModalVisible && (*/}
                                      {/*      <TermsAndConditionsModal*/}
                                      {/*        open={isTermsModalVisible}*/}
                                      {/*        onClose={() =>*/}
                                      {/*          setTermsModalVisibility(false)*/}
                                      {/*        }*/}
                                      {/*      />*/}
                                      {/*    )}*/}
                                      {/*  </div>*/}
                                      {/*) : null}*/}
                                    </div>
                                  ) : null}
                                </TabPanel>
                              </>
                            );
                          })}
                      </Tabs>
                    ) : (
                      <>
                        <div className="">
                          <div
                            className={`${
                              item?.children.length > 0
                                ? "lg:mb-[100px] sm : mb-[40px]"
                                : ""
                            }`}
                          >
                            <div
                              className={`${
                                item?.children.length > 0
                                  ? "lg:mb-[70px] sm : mb-[40px] lg:pt-[64px]"
                                  : ""
                              }`}
                            >
                              {item?.desk_image && (
                                // <img src={`${configuration.IMG_URL}${item?.desk_image}`} alt="" />

                                <CommonHomeCard
                                  Id={
                                    "MultiTabHomeSuzuki" +
                                    item?.title?.replace(/\s+/g, "")
                                  }
                                  key={i}
                                  imgSrc={`${configuration.IMG_URL}${item?.desk_image}`}
                                  imgMobileSrc={`${configuration.IMG_URL}${item?.mob_image}`}
                                  alt_text={item?.alt_text}
                                  // formsData={formsData}
                                  // form={item?.form_id}
                                  imgHeight={738}
                                  imgWidth={1920}
                                  // contentClassName={` md:max-w-[80%] `}
                                  // headClassName={`min-h-[75px] lg:min-h-[75px] md:min-h-[75px]`}
                                  // descriptClass={`h-[64px] lg:h-[64px] md:h-[64px]`}
                                  imgMobileHeight={564}
                                  imgMobileWidth={768}
                                  // cardTitle={item?.title}
                                  // cardDescription={item?.desc}
                                  // cardBtnText={item?.cta_text}
                                  cardLink={
                                    item?.ctaLink
                                      ? CarPageAllSections.includes(
                                          data?.tabsData?.[
                                            i
                                          ]?.parentsFormObject?.ctaLink?.split(
                                            "#"
                                          )?.[1]
                                        )
                                        ? `/cars/${item?.ctaLink}`
                                        : item?.ctaLink
                                      : item?.ctaLink
                                  }
                                  // btnType={"button_dark_arrow"}
                                />
                              )}
                            </div>
                            {(item?.short_desc || item?.desc) && (
                              <div className="flex  sm : flex-col lg:flex-row  container mr-auto ml-auto">
                                <div className=" sm : w-full">
                                  <div
                                    className="sm : w-full font-suzukiBold sm : pb-5 unreset"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.short_desc,
                                    }}
                                  >
                                    {/* {item?.short_desc} */}
                                  </div>
                                </div>
                                <div className=" sm : w-full flex items-center justify-center">
                                  <div className=" sm : w-full text-[14px] lg:w-[535px] font-suzukiRegular font-[400] unreset">
                                    {/* <p>{item?.desc}</p> */}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item?.desc,
                                      }}
                                    ></div>

                                    {item?.form_id &&
                                    item?.form_id.length > 0 ? (
                                      <>
                                        <div className="compareItem__CTAs !block">
                                          Id=
                                          {"MultiTabHomeSuzuki" +
                                            item?.title?.replace(/\s+/g, "")}
                                          <LinkButton
                                            type={"button_dark_arrow_button"}
                                            buttontext={item?.ctaText}
                                            classname="button_dark_arrow fullBorder__btn"
                                            onClick={() => {
                                              dispatch(
                                                toggleForm(item?.form_id)
                                              );
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      item?.ctaLink && (
                                        <LinkButton
                                          Id={
                                            "MultiTabHomeSuzuki" +
                                            item?.title?.replace(/\s+/g, "")
                                          }
                                          type={"button_dark_arrow"}
                                          buttontext={item?.ctaText}
                                          href={
                                            item?.ctaLink
                                              ? CarPageAllSections.includes(
                                                  data?.tabsData?.[
                                                    i
                                                  ]?.parentsFormObject?.ctaLink?.split(
                                                    "#"
                                                  )[1]
                                                )
                                                ? `/cars/${item?.ctaLink}`
                                                : item?.ctaLink
                                              : item?.ctaLink
                                          }
                                          classname="button_dark_arrow"
                                          target=""
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* Section for showing available models under parent tab */}
                          {item.models && item.models?.length > 0 ? (
                            <div>
                              {/*<div className="mb-[65px] text-[30px] font-suzukiBold sm : ml-[10px] container ml-auto">*/}
                              {/*  {data?.tabsData[*/}
                              {/*    i*/}
                              {/*  ]?.parentsFormObject?.modeltext?.toUpperCase()}*/}
                              {/*</div>*/}
                              <div className="mb-[65px] text-[30px] font-suzukiBold sm : ml-[10px] container ml-auto">
                                 <h1>
                                   {item?.modeltext?.toUpperCase()}
                                 </h1>
                              </div>
                              <div className="container mx-auto flex flex-wrap gap-[27px]">
                                {item?.models?.map((carData, i) => {
                                  return (
                                    <>
                                      <div className="2xl:w-[23%] lg:w-[21%] md:w-[22%] w-[46%] flex items-center justify-center mb-[20px]">
                                        <div className=" w-[100%]">
                                          <HomeSuzukiCard
                                            Id={
                                              "MultiTabHomeSuzuki" +
                                              carData?.model_code?.replace(
                                                /\s+/g,
                                                ""
                                              )
                                            }
                                            key={i}
                                            modelCode={carData?.model_code}
                                            imgSrc={`${configuration.IMG_URL}${carData?.image}`}
                                            cardTitle={carData?.model_name}
                                            // cardItemPrice={carData?.retail_price}
                                            // itemFuelType={carData?.fuel_type?.toUpperCase()}
                                            // itemSeater={carData?.vehicle_type}
                                            cardLink={carData?.page_url}
                                            // rable_price={carData?.price3}
                                            // price={carData?.price2}

                                            car_page_price_one={
                                              carData.car_page_price_one
                                            }
                                            car_page_pricetext_two={
                                              carData.car_page_pricetext_two
                                            }
                                            car_page_pricetext_three={
                                              carData.car_page_pricetext_three
                                            }
                                            car_page_price_two={
                                              carData.car_page_price_two
                                            }
                                            car_page_price_three={
                                              carData.car_page_price_three
                                            }
                                            additionalInformation={
                                              carData?.additionalInformation
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>

                              {Constants.allcomponents
                                .SHOW_TERMS_AND_CONDITIONS_MULTITAB ==
                              "true" ? (
                                <div className="container">
                                  <div
                                    className=" mt-10 underline text-[#3366CC] cursor-pointer w-fit"
                                    onClick={() =>
                                      setTermsModalVisibility(true)
                                    }
                                  >
                                    {
                                      Constants.allcomponents
                                        .TERMS_AND_CONDITIONS
                                    }
                                    *
                                  </div>
                                  {isTermsModalVisible && (
                                    <TermsAndConditionsModal
                                      open={isTermsModalVisible}
                                      onClose={() =>
                                        setTermsModalVisibility(false)
                                      }
                                    />
                                  )}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </TabPanel>
                </>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default MultitabCommmon;

import Image from "next/image";
import style from "../../styles/preference.module.css";

// Advantage grid component for Preference program page
const AdvantageGrid = ({
  image,
  heading,
  desc,
  widthManage,
  marginManage,
  count,
  hideImage,
  id,
}) => {
  return (
    <div className={`${style.advantage_grid}  ${widthManage}`}>
      <div className={`${style.advantage_grid_img}  ${marginManage}`}>
        {!hideImage && (
          <Image
            // src = "/assets/images/preference-program/calculator.svg"
            src={image}
            width={40}
            height={40}
          />
        )}

        {count && (
          <div className="advantage-count-wrapper text-center">
            <span className={style.advantage_count}>{count}</span>
          </div>
        )}
      </div>

      <div className="advantage-grid-content unreset">
        <h2 className={style.advantage_content}>{heading}</h2>
        <p
          className={style.advantage_desc}
          dangerouslySetInnerHTML={{ __html: desc }}
        ></p>
      </div>

      {id && <span className={style.advantage_id}>{id}</span>}
    </div>
  );
};

export default AdvantageGrid;

import style from "../../styles/preference.module.css";

// Common Heading Component for HU-PREFERENCE PROGRAM PAGE
const CommonHeading = ({ heading, desc }) => {
  return (
    <>
      <div className={style.common_heading}>
        <h1 className={style.common_heads}>{heading}</h1>
        <p className={style.common_descs}>{desc}</p>
      </div>
    </>
  );
};

export default CommonHeading;

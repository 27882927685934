import React, { useState } from "react";
import LinkButton from "../button";
import Image from "next/image";
import ReactPlayer from "react-player";
import { configuration } from "../../helpers/config";
import { CarPageAllSections } from "../../pages/pages/templates/all_components";
import youtubeThumbnail from "youtube-thumbnail";
import { Modal } from "react-responsive-modal";
import useIsMobile from "../../hooks/useIsMobile";
import { BsYoutube } from "react-icons/bs";
import { formatDateByCountryCode, validateUrl,redirectRoute } from "../../utils/GenUtils";
import { toggleForm } from "../../redux/ActionCreator";
import { useDispatch } from "react-redux";
import Link from "next/link";

// component to render inside of topic slider components, contains modal for images and videos as well .
export default function TopicSlide({
  showHeading,
  cardClassName,
  imgSrc,
  alt_text,
  cardDate,
  cardDateEnabled,
  cardDescription,
  cardBtnText,
  target,
  cardTitle,
  cardLink,
  form,
  Id,
  videoSrc,
  date,
  date_enabled,
  filename_pdf,
  title
}) {

  const [hide, setHide] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const mobile = useIsMobile();
  const dispatch = useDispatch();
  // validating url
  let isValidUrl = validateUrl(cardLink);
  // cardLink=redirectRoute(cardLink,"/vnm-raider","/404");

  return (
    <div
      className={`${hide && "hidden"} ${
        cardClassName ? cardClassName + " topicCard mr-[50px]" : "topicCard"
      }`}
    >
      <Modal
        onClose={() => setShowPlayer(false)}
        open={showPlayer}
        classNames={{
          modal: "topicSlide__modal",
        }}
      >
        <ReactPlayer
          onClick
          fullScreen={true}
          url={videoSrc}
          volume={1}
          width={mobile ? "90vw" : "65vw"}
          height={mobile ? "50vw" : "35vw"}
          playsinline
        />
      </Modal>
      <div className="topicCard__image" id={Id}>
        {videoSrc && videoSrc.includes("youtube.com") ? (
          <button onClick={() => setShowPlayer(true)} className="relative">
            <span className="absolute z-20 top-[50px] left-[110px]">
              <BsYoutube size={50} color={"red"} />
            </span>
            <Image
              src={youtubeThumbnail(videoSrc).medium.url}
              className=""
              width={398}
              height={221}
              alt=""
              onError={(e) => setHide(true)}
            />
          </button>
        ) : (
          <div>
          {cardLink ?
        <div>
        <Link  
          className="cursor-pointer"
          href={!isValidUrl ? (CarPageAllSections.includes(cardLink.split("#")[1])?`/cars/${cardLink}`:`/pages/${cardLink}`): cardLink}
          // href={CarPageAllSections.includes(cardLink.split("#")[1])?`/cars/${cardLink}`:cardLink}
           >
           <div className="cursor-pointer"> 
           {imgSrc ? (
  <Image
    src={`${configuration.IMG_URL}${imgSrc}`}
    className=""
    width={398}
    height={221}
    alt={alt_text}
    onError={(e) => setHide(true)}
  />
) : (!imgSrc) && filename_pdf ? (
  <div className="pdf-icon-container">
    <img
      src={`${configuration.IMG_URL}static_img/assets/images/pdficon.png`}
      alt="PDF Icon"
      className="pdf-icon"
    />
  </div>
) : null}

          </div>
          </Link>
          </div>
          :
          <div>
        {imgSrc ? (
  <Image
    src={`${configuration.IMG_URL}${imgSrc}`}
    className=""
    width={398}
    height={221}
    alt={alt_text}
    onError={(e) => setHide(true)}
  />
) : (!imgSrc) && filename_pdf ? (
  <div className="pdf-icon-container">
    <img
      src={`${configuration.IMG_URL}static_img/assets/images/pdficon.png`}
      alt="PDF Icon"
      className="pdf-icon"
    />
  </div>
) : null}
          </div>
}

          </div>
        
         
        )}
      </div>
      <div className="topicCard__details px-[4px]">
        <div className="topicCard__details--info">
        {title && (
      <h1
        className="kids-print-heading text-[24px] h-[75px] lg:h-[74px] md:h-[74px] text-primary-blue text-section-heading mb-[10px] !h-auto"
        style={{ marginTop: "10px", marginBottom: "5px" }}
      >
        {title}
      </h1>
    )}
     {showHeading && (
            <h3 className="topicCard__details--title">{cardTitle}</h3>
          )}
      {cardDescription && (
        <p
          className="topicCard__details--desc"
          dangerouslySetInnerHTML={{ __html: cardDescription }}
        />
      )}
          {cardDateEnabled && (
            <p className="topicCard__details--date">
              {formatDateByCountryCode(cardDate)} 
            </p>
          )}
          {/* <p
            className="topicCard__details--desc overflow-hidden min-h-[64px] max-h-[64px] unreset p-[5px]"
            dangerouslySetInnerHTML={{ __html: cardDescription }}
          /> */}
          {cardLink && cardBtnText && (
            <LinkButton
            Id={Id}
              type={"button_dark_arrow"}
              buttontext={cardBtnText}
              target={target}
              href={!isValidUrl ? (CarPageAllSections.includes(cardLink.split("#")[1])?`/cars/${cardLink}`:`/pages/${cardLink}`): cardLink}
              classname="button_dark_arrow"
            />
          )}
          {cardBtnText && !cardLink && form && (
            <div className="compareItem__CTAs !block">
            <LinkButton
            Id={Id}
              type={"button_dark_arrow_button"}
              buttontext={cardBtnText}
              onClick={() => {
                dispatch(toggleForm(form));
              }}
              classname="button_dark_arrow fullBorder__btn"
            />
             </div>
          )}
        </div>
      </div>
    </div>
  );
}
